<template>
  <div>Landing Page</div>
</template>

<script>
import { ROUTER_URL } from "@/constants/urls";

export default {
  name: "LandingPage",
  components: {},
  data() {
    return {};
  },
  created() {},
  computed: {},
  watch: {},
  methods: {},
  mounted() {
    this.$router.push({
      name: ROUTER_URL.auth.children.login.name,
    });
  },
};
</script>

<style scoped>
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
